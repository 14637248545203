import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'checkboxes'];
  static values = {
    defaultText: String,
    className: String,
  };
  declare contentTarget: HTMLElement;
  declare checkboxesTargets: HTMLInputElement[];
  declare defaultTextValue: string;
  declare classNameValue: string;

  connect(): void {
    this.changeSelectedLabel();
  }

  changeSelectedLabel(): void {
    this.contentTarget.textContent = this.selectedValues();
    this.contentTarget.insertAdjacentHTML('beforeend', '<span class="arrow_down"></span>');
  }

  private selectedValues(): string {
    const optionCheckboxes = document.querySelectorAll(`.${this.classNameValue}:checked`);
    // FIXME: 直す
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return this.checkedAllLabel(optionCheckboxes) || [...optionCheckboxes].map((v) => v.dataset.label).join(', ');
  }

  private checkedAllLabel(optionCheckboxes): string {
    const length = optionCheckboxes.length;
    if (!length || optionCheckboxes[0]?.value == 'unspecified') {
      return `${this.defaultTextValue}: 指定なし`;
    } else if (length == this.checkboxesTargets.length) {
      return `${this.defaultTextValue}: すべて`;
    }
  }
}
