import { Controller } from '@hotwired/stimulus';
import bookBoardUsersOutletController from 'controllers/book_board_users_controller';

export default class extends Controller {
  static targets = ['text'];
  static outlets = ['book-board-users'];
  declare textTarget: HTMLElement;
  declare bookBoardUsersOutlet: bookBoardUsersOutletController;
  declare hasBookBoardUsersOutlet: boolean;

  private textDisplay: string;

  connect(): void {
    this.textDisplay = this.textTarget.style.display;
    if (this.textTarget.dataset.displayDefault !== 'true') {
      this.textTarget.style.display = 'none';
    }
  }

  changeStyleSetting(event: MouseEvent): void {
    const target = event.currentTarget as HTMLInputElement;
    if (!target.checked) {
      return;
    }

    this.toggleText(target.value);
    if (this.hasBookBoardUsersOutlet) {
      // outlet では直接プロパティを変更できないので引数で渡す
      this.bookBoardUsersOutlet.resetMeetingStyleSetting(target.value);
    }
  }

  private toggleText(value): void {
    if (value == 'multi') {
      this.textTarget.style.display = this.textDisplay;
    } else {
      this.textTarget.style.display = 'none';
    }
  }
}
