import * as Rollbar from 'rollbar';

const enabledEnvironments = ['production', 'staging'];
const accessToken =
  process.env.RAILS_ENV === 'production' ? '9819b020d46b4c6fa52f23ad04faf16f' : '0ca2783aae77491a85566b43d73b30d3';

const rollbar = new Rollbar({
  accessToken,
  // NOTE: 将来的にtrueにしたいので明示的にfalseを指定している
  // TODO: captureUncaughtとcaptureUnhandledRejectionsをtrueにする
  captureUncaught: false,
  captureUnhandledRejections: false,
  environment: process.env.RAILS_ENV,
  enabled: process.env.RAILS_ENV ? enabledEnvironments.includes(process.env.RAILS_ENV) : false,
});

export { rollbar };
