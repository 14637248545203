import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  declare contentTarget: HTMLElement;

  toggle(): void {
    this.contentTarget.classList.toggle('active');
  }

  toggleByEnabled(event: InputEvent): void {
    const target = (event.currentTarget || event.target) as HTMLInputElement;
    if (target.checked && !this.contentTarget.classList.contains('active')) {
      this.contentTarget.classList.add('active');
    } else if (!target.checked && this.contentTarget.classList.contains('active')) {
      this.contentTarget.classList.remove('active');
    }
  }
}
